import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,2,11,12];

export const dictionary = {
		"/account/integrations": [15,[2]],
		"/account/invoices": [16,[2]],
		"/account/preferences": [17,[2]],
		"/account/profile": [18,[2]],
		"/account/subscription": [19,[2]],
		"/app": [20,[3]],
		"/checkout": [21],
		"/checkout/update-payment": [22],
		"/community/documents/[id]": [23,[4]],
		"/community/notes/[id]": [24,[4]],
		"/community/templates/[id]": [25,[4]],
		"/configure-integration/bp/setup": [26],
		"/consult": [27],
		"/create-account": [28,[5]],
		"/create-account/details": [29,[5]],
		"/create-account/password": [30,[5]],
		"/customisation/direct-dictate": [~31,[6]],
		"/customisation/document-personalisation": [32,[6]],
		"/customisation/find-and-replace": [33,[6]],
		"/customisation/general": [34,[6]],
		"/customisation/letterheads": [35,[6]],
		"/customisation/macros": [36,[6]],
		"/customisation/note-personalisation": [37,[6]],
		"/customisation/patient-forms": [38,[6]],
		"/demo": [39,[7]],
		"/demo/bp-setup": [40,[7]],
		"/demo/gentu-setup": [41,[7]],
		"/dictate": [42,[8]],
		"/docs": [43],
		"/downtime": [~44],
		"/form/[code]": [45],
		"/history": [46,[9]],
		"/history/documents/[slug]": [~47,[9,10]],
		"/logging-in": [48],
		"/login": [49],
		"/login/mfa": [50],
		"/organisation/manage": [51,[11]],
		"/organisation/preferences": [52,[11]],
		"/organisation/subscription": [53,[11]],
		"/organisation/usage": [54,[11]],
		"/personalisation/templates/legacy/[slug]": [56],
		"/personalisation/templates/[id]": [55],
		"/preconsult/[code]": [57],
		"/security/mfa": [58,[12]],
		"/signin": [59,[13]],
		"/signin/mfa": [60,[13]],
		"/signin/password": [61,[13]],
		"/signup": [62],
		"/update-password": [63,[14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';